<script>
export default {
  name: 'ModelScopeDrawers',
  props: {
    projectKey: {
      type: String,
      required: true,
    },
    selectedDiscipline: {
      type: String,
      required: false,
      default: 'Architect',
    },
    selectedObject: {
      type: Object,
      required: false,
      default: null,
    },
    selectionModeOn: {
      type: Boolean,
      require: false,
      default: false,
    },
  },
  data() {
    return {
      drawer: true,
      secondaryBar: false,
      secondaryMode: '',
      flyoutBar: false,
      flyoutMode: '',
      sectioningBar: false,
      sectioningMode: '',
      selectingBar: false,
      selectingMode: '',
      items: [
        {
          title: 'Disciplines',
          icon: 'mdi-google-circles-extended',
          disabled: false,
          callback: this.useDisciplines,
          mode: 'secondary',
        },
        {
          title: 'Model Hierarchy',
          icon: 'mdi-file-tree',
          disabled: false,
          callback: this.useModelHierarchy,
          mode: 'flyout',
        },
        {
          title: 'Filters',
          icon: 'mdi-filter',
          disabled: true,
          callback: this.useFilters,
          mode: 'flyout',
        },
        {
          title: 'Overrides',
          icon: 'mdi-invert-colors',
          disabled: true,
          callback: this.useOverrides,
          mode: 'flyout',
        },
        {
          title: 'Model Versions',
          icon: 'mdi-history',
          disabled: true,
          callback: this.useModelVersions,
          mode: 'flyout',
        },
        { type: 'spacer' },
        {
          title: `Sectioning`,
          options: { true: '(not implemented)', false: '(not implemented)' },
          icon: 'mdi-vector-combine',
          selectedIcon: 'mdi-vector-intersection',
          disabled: false,
          callback: this.toggleSectioning,
          mode: 'sectioning',
        },
        {
          title: `Selections`,
          options: { true: 'On', false: 'Off' },
          icon: 'mdi-mouse-off',
          selectedIcon: 'mdi-mouse',
          disabled: false,
          callback: this.toggleSelecting,
          mode: 'selecting',
        },
      ],
      secondaryItems: [
        { title: 'Setting Out Model (HHBR)', disabled: true },
        { title: 'Architect (HHBR)', disabled: false },
        { title: 'Landscape (East)', disabled: true },
        { title: 'Structure (Arup)', disabled: true },
        { title: 'Services (Arup)', disabled: true },
      ],
      activeItem: 'Disciplines',
      multipleSelectioMode: false,
      modelHierarchy: [],

      disciplineOptions: [],
      zonalHierarchy: [
        {
          id: 1,
          name: 'Buildings',
          children: [{ id: 2, name: 'Plot B' }],
        },
        {
          id: 8,
          name: 'Levels',
          children: [
            {
              id: 9,
              name: 'B_A-B-15',
            },
            {
              id: 10,
              name: 'B_A-B-14',
            },
            {
              id: 11,
              name: 'B_A-B-13',
            },
            {
              id: 12,
              name: 'B_A-B-12',
            },
            {
              id: 13,
              name: 'B_A-B-11',
            },
            {
              id: 14,
              name: 'B_A-B-10',
            },
            {
              id: 15,
              name: 'B_A-B-09',
            },
            {
              id: 16,
              name: 'B_A-B-08',
            },
            {
              id: 17,
              name: 'B_A-B-07',
            },
            {
              id: 18,
              name: 'B_A-B-06',
            },
            {
              id: 19,
              name: 'B_A-B-05',
            },
            {
              id: 20,
              name: 'B_A-B-04',
            },
            {
              id: 21,
              name: 'B_A-B-03',
            },
            {
              id: 22,
              name: 'B_A-B-02',
            },
            {
              id: 23,
              name: 'B_A-B-01',
            },
            {
              id: 24,
              name: 'B_A-B-0M',
            },
            {
              id: 25,
              name: 'B_A-B-00',
            },
            {
              id: 26,
              name: 'B_A-B-0B',
            },
          ],
        },
        {
          id: 27,
          name: 'Zones',
          children: [
            { id: 28, name: 'Plant' },
            { id: 29, name: 'Market Residential' },
            { id: 30, name: 'Affordable Residential' },
          ],
        },
        {
          id: 31,
          name: 'Types',
          children: [
            { id: 32, name: 'Baseline' },
            { id: 33, name: 'Design' },
          ],
        },
      ],
    };
  },
  watch: {
    selectionModeOn(newValue) {
      this.selectingBar = newValue;
      this.selectingMode = newValue ? 'Selections' : '';
    },
  },
  created() {
    this.selectingBar = this.selectionModeOn;
    this.selectingMode = this.selectionModeOn ? 'Selections' : '';
  },
  mounted() {
    this.modelHierarchy = this.zonalHierarchy;
  },
  methods: {
    useDisciplines() {
      console.log('useDisciplines');
    },
    useModelHierarchy() {
      console.log('useModelHierarchy');
    },
    useModelScope() {
      console.log('useModelScope');
    },
    useFilters() {
      console.log('useFilters');
    },
    useOverrides() {
      console.log('useOverrides');
    },
    useModelVersions() {
      console.log('useModelVersions');
    },
    toggleSectioning() {
      this.sectionBar = !this.sectionBar;
    },
    toggleSelecting() {
      this.$emit('toggle-selecting', this.selectingBar);
    },
    handleMainBarClick(item) {
      const { mode, title, callback } = item;

      if (this[`${mode}Mode`] === title) {
        this[`${mode}Mode`] = '';
        this[`${mode}Bar`] = false;
        callback();
      } else {
        this[`${mode}Mode`] = title;
        this[`${mode}Bar`] = true;
        callback();
      }
    },
    toggleState(mode) {
      return this[`${mode}Bar`];
    },
  },
};
</script>

<template>
  <!-- <v-navigation-drawer permanent width="100%"> -->
  <div class="model-viewer-options">
    <v-card v-if="selectedObject" class="object-data" flat rounded-xl>
      <v-card-text>
        <pre>{{
          JSON.stringify(
            {
              metadata: {
                ...selectedObject.metadata,
                uploadedBy: 'jonathon.broughton@grfn.com',
              },
              object: {
                name: selectedObject.object.name,
                type: selectedObject.object.type,
              },
              customData: selectedObject.userData,
              'GRFN Test Data': {
                'Has: TypeCode': false,
                'Valid: Type Code': false,
                'Is: Baseline': false,
                'Has: System': false,
                'Valid: System': false,
                'Has: Mark': false,
              },
            },
            undefined,
            '\t'
          )
        }}</pre>
      </v-card-text>
    </v-card>
    <v-container :class="{ second: secondaryBar }" class="model-scope-drawer">
      <!-- <v-card></v-card> -->
      <v-row class="main-bar rounded-lg" justify="space-around">
        <v-col v-for="item in items" :key="item.title" class="pa-2">
          <v-spacer v-if="item.type === 'spacer'" /><v-tooltip v-else top>
            <template #activator="{ on, attrs }">
              <v-btn
                :class="{ 'active-button': toggleState(item.mode) }"
                v-bind="attrs"
                :disabled="item.disabled"
                icon
                small
                v-on="on"
                @click="handleMainBarClick(item)"
              >
                <template v-if="!toggleState(item.mode)">
                  <v-icon>{{ item.icon }}</v-icon> </template
                ><template v-else-if="item.selectedIcon">
                  <v-icon>{{ item.selectedIcon }}</v-icon> </template
                ><template v-else>
                  <v-icon>{{ item.icon }}</v-icon>
                </template>
              </v-btn> </template
            ><span>{{ item.title }} </span>
            <span v-if="item.options">{{
              item.options[!toggleState(item.mode)]
            }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row v-if="secondaryBar" class="second-bar rounded-lg">
        <v-col v-for="item in secondaryItems" :key="item.title" class="pa-2">
          <v-btn :disabled="item.disabled" small>
            <span>{{ item.title }}</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="flyoutBar" class="flyout">
      <v-treeview
        ref="model-hierarchy"
        :items="modelHierarchy"
        :multiple-active="multipleSelectioMode"
        class="model-hierarchy"
        selectable
        selected-color="default--lighten-4"
        open-all
      />
    </v-container>
  </div>
</template>

<style lang="scss">
.active-button.v-btn.v-btn--icon {
  color: var(--v-action-base);
}

.model-viewer-options {
  position: absolute;
  bottom: 8px;
  right: 15px;
  left: 15px;
  z-index: 5;
}

.model-scope-drawer {
  z-index: 5;
  display: flex;
  flex-direction: column-reverse;

  .row + .row {
    margin-bottom: 8px;
    position: relative;
    top: -12px;
  }
}

.main-bar,
.second-bar {
  z-index: 15;
}
.main-bar {
  & .col {
    z-index: 6;
    text-align: center;
    max-width: 50px;
  }
}
.flyout {
  position: fixed;
  right: 15px;
  z-index: 10;
  top: 75px;
  bottom: 92px;
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
  max-width: 300px;
  zoom: 0.75;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #f5f5f5;
  }
}

.second + .flyout {
  bottom: 144px;
}
.main-bar,
.second-bar,
.flyout {
  background-color: hsla(0, 0%, 100%, 0.75);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.model-hierarchy {
  .v-icon.v-icon:focus::after {
    opacity: 0;
  }
}

.v-card.object-data {
  position: fixed;
  top: 95px;
  width: 450px;
  max-width: 450px;
  // max-height: 950px;
  // font-size: 0.75rem;
  z-index: 0;
  background-color: hsla(0, 0%, 100%, 0.5);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(10px);
  opacity: 0.85;
  zoom: 0.75;
  pointer-events: none;
  border-radius: 1rem;
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
