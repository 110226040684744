<script>
/* eslint-disable no-unused-vars */
import * as THREE from 'three';
import { sRGBEncoding, Vector2 } from 'three';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { SSAOPass } from 'three/examples/jsm/postprocessing/SSAOPass';

const _v1 = new THREE.Vector3();
const _v2 = new THREE.Vector3();
const _v3 = new THREE.Vector3();

export default {
  name: 'ModelCanvas',
  props: {
    selectionModeOn: {
      type: Boolean,
      required: false,
      default: false,
    },
    modelSet: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },
  },
  data: function () {
    return {
      modelsLoading: false,
      loadingProgress: 0,
    };
  },
  computed: {
    rotate: function () {
      if (this.speed === '') {
        return 0;
      } else {
        return this.$scene.speed;
      }
    },
  },
  watch: {
    modelSet(to) {
      console.log(to.length);

      const newLibrary =
        to.length === 0
          ? []
          : [...to].map(model => {
              return { url: model.public_url, id: model.id };
            });

      this.loadModels(newLibrary);
    },
    selectionModeOn(newVal) {
      this.$scene.selectionModeOn = newVal;

      if (newVal) {
        // console.log('turning on');
        this.$scene.container.addEventListener(
          'click',
          this.rendererClick,
          false
        );
      } else {
        // console.log('turning off');
        this.$scene.container.removeEventListener('click', this.rendererClick);
        if (this.$scene.selectedObject) {
          this.$scene.selectedObject.material.emissive.setHex(0x000000);
          this.$scene.selectedObject = null;

          this.$emit('selected', null);
        }
      }
    },
  },
  beforeCreate() {
    const scene = new THREE.Scene();
    // scene.background = null;

    const raycaster = new THREE.Raycaster();
    const mousePosition = new THREE.Vector2();

    const camera = new THREE.PerspectiveCamera(
      40,
      window.innerWidth / window.innerHeight,
      0.1,
      50
    );
    const renderer = new THREE.WebGLRenderer({ alpha: true });
    const composer = new EffectComposer(renderer);
    const modelsGroup = new THREE.Group({
      name: 'Project Model',
      matrixAutoUpdate: false,
      castShadow: true,
      receiveShadow: true,
    });
    const contextGroup = new THREE.Group({
      name: 'Context',
      matrixAutoUpdate: false,
      castShadow: false,
      receiveShadow: true,
    });
    const spaceGroup = new THREE.Group({
      name: 'Spaces',
      matrixAutoUpdate: false,
      castShadow: false,
      receiveShadow: false,
    });

    const mockGroundPlaneMaterial = new THREE.MeshBasicMaterial({
      side: THREE.DoubleSide,
      color: 'hsl(145, 100%, 100%)',
      wireframe: false,
      opacity: 0.5,
    });

    // Lights
    const world = new THREE.HemisphereLight(0xffeeb1, 0x080820, 3);
    world.name = 'World';
    const ambient = new THREE.AmbientLight(0xf0e0d0, 7.5);

    const light1 = new THREE.DirectionalLight('hsl(0,100%,100%)', 1.525);
    light1.name = 'Light 1';
    light1.position.set(15, 15, -10);
    light1.castShadow = true;

    const light2 = new THREE.DirectionalLight('hsl(0,100%,100%)', 1.525);
    light2.name = 'Light 2';
    light2.position.set(-5, 15, 5);
    light2.castShadow = true;

    const helper = new THREE.DirectionalLightHelper(light1, 1, 0x0066ff);
    helper.name = 'Light Helper';
    const helper2 = new THREE.DirectionalLightHelper(light2, 1, 0xff6600);
    helper2.name = 'Light 2 Helper';

    const directional = [light1, light2];
    const spot = [];

    const cameraLight = new THREE.DirectionalLight('hsl(0,100%,100%)', 2);
    cameraLight.name = 'Camera Light';
    cameraLight.castShadow = true;
    const helper3 = new THREE.DirectionalLightHelper(cameraLight, 1, 0x00ff66);

    const boxHelper = new THREE.BoxHelper(modelsGroup, 0xffff00);
    boxHelper.name = 'Building BBox';

    const ssaoPass = new SSAOPass(scene, camera);
    ssaoPass.kernelRadius = 0.05;
    ssaoPass.minDistance = 0.00005;
    ssaoPass.maxDistance = 0.0001;
    ssaoPass.normalRenderTarget.depthTexture.type = THREE.FloatType;
    ssaoPass.output = SSAOPass.OUTPUT.Default;

    const controls = null;

    this.$scene = {
      black: 0x000000,
      boxHelper,
      camera,
      center: new THREE.Vector3(),
      clock: new THREE.Clock(),
      composer,
      container: undefined,
      contextGroup,
      controls,
      helpers: [helper, helper2, helper3, boxHelper],
      intersections: [],
      lights: { world, ambient, directional, spot, camera: cameraLight },
      mockGroundPlaneMaterial,
      modelList: {},
      modelsGroup,
      mounted: false,
      mousePosition,
      pauseSSAO: false,
      postprocessing: true,
      raycaster,
      renderer,
      scene,
      selectedObject: null,
      selectionModeOn: false,
      spaceGroup,
      speed: 0.05,
      ssaoPass,
      white: 0xffffff,
    };
  },
  activated: function () {
    // console.log('activated');
    // this.startAnimationLoop();
  },
  deactivated: function () {
    // console.log('deactivated');
    // this.stopAnimationLoop();
  },
  created: function () {
    if (this.$scene) {
      this.$scene.modelsGroup && this.$scene.scene.add(this.$scene.modelsGroup);
      // this.$scene.contextGroup &&
      //   this.$scene.scene.add(this.$scene.contextGroup);
      // this.$scene.spaceGroup && this.$scene.scene.add(this.$scene.spaceGroup);
      this.$scene.camera && this.$scene.scene.add(this.$scene.camera);
      this.addLights();
    }
    this.$scene.selectionModeOn = this.selectionModeOn;
  },
  mounted: function () {
    // On Mounted
    this.$scene.mounted = true;

    // DOM Responsive
    this.$scene.container = document.getElementById('canvas-wrapper');
    if (this.selectionModeOn) {
      this.$scene.container.addEventListener(
        'click',
        this.rendererClick,
        false
      );
    }

    // Initial Setup
    this.setRenderer();
    this.setSSAO();
    this.setComposer();
    this.addControls();

    // Load Model
    // this.loadContext();
    this.loadModels(
      [this.modelSet].map(model => {
        return { url: model.public_url, id: model.id };
      })
    );

    // Start Animation Loop
    // this.animate();
    // this.startAnimationLoop();

    // this.$root.$on('cancelAnimation', (data) => {
    //   this.stopAnimationLoop();
    // });
  },
  methods: {
    loadContext(contextLibrary) {
      contextLibrary = contextLibrary || [
        {
          url: 'https://storage.googleapis.com/grfn-gltf/RSB/HHBR_AR/211102/glb/B_AOD.glb',
          loaded: false,
          name: 'AOD Site Bounds',
        },
      ];

      this.$scene.contextGroup.remove(...this.$scene.contextGroup.children);
      void contextLibrary.map(async (model, idx) => {
        console.log('url', model.url);
        return await this.loadModel(model.url)
          .then(result => {
            contextLibrary[idx].loaded = true;

            const model = result.scene;
            model.position.set(model.position.x, 0.38, model.position.z);

            model.traverse(child => {
              if (child.isMesh) {
                child.castShadow = true;
                child.receiveShadow = true;
              }
            });
            return result.scene;
          })
          .then(model => {
            model.name = contextLibrary[idx].name;
            this.$scene.contextGroup.add(model);
            // console.debug('Context Loaded');
          });
      });
    },
    loadModels(modelLibrary, clear = false) {
      this.modelsLoading = true;

      if (!modelLibrary || modelLibrary.length === 0 || clear === true) {
        this.$scene.modelsGroup.remove(...this.$scene.modelsGroup.children);
        this.$scene.modelList = {};
      }

      if (!modelLibrary) {
        return;
      }

      modelLibrary = modelLibrary.map(model => {
        return { ...model, loaded: false };
      });

      for (const modelRecord of modelLibrary) {
        if (!this.$scene[modelRecord.id]) {
          this.$scene.modelList = {
            ...this.$scene.modelList,
            [modelRecord.id]: modelRecord,
          };

          if (modelRecord.url) {
            this.loadModel(modelRecord.url)
              .then(result => {
                result.scene.name = modelRecord.url;
                this.$scene.modelList[modelRecord.id].loaded = true;

                result.scene.traverse(child => {
                  const parentData = child.parent ? child.parent.userData : {};
                  child.userData = {
                    ...child.userData,
                    ...parentData,
                    url: modelRecord.url,
                  };
                  child.castShadow = true;
                  child.receiveShadow = true;

                  if (child.isMesh) {
                    child.material.side = THREE.DoubleSide;
                    child.material.transparent = false;
                  }
                });

                return result.scene;
              })
              .then(model => {
                const unloadedModelCount = Object.keys(
                  this.$scene.modelList
                ).filter(m => !m.loaded).length;
                this.$scene.loadingProgress =
                  100 -
                  (unloadedModelCount /
                    Object.keys(this.$scene.modelList).length) *
                    100;

                if (model.name.includes('AOD')) {
                  this.$scene.contextGroup.add(model);
                } else if (model.name.includes('Space')) {
                  this.$scene.spaceGroup.add(model);
                } else {
                  this.$scene.modelsGroup.add(model);
                }

                if (this.$scene && this.$scene.boxHelper) {
                  this.$scene.boxHelper.update();
                }
                this.zoomExtents();

                if (unloadedModelCount === 0) {
                  this.modelsLoading = false;
                }

                this.render();
              });
          }
        }
      }

      if (modelLibrary.length === 0) {
        this.render();
      }
    },
    addControls() {
      this.$scene.controls = new OrbitControls(
        this.$scene.camera,
        this.$scene.container
      );
      this.$scene.controls.domElement = this.$scene.renderer.domElement;
      this.$scene.controls.maxDistance = 200;
      this.$scene.controls.minDistance = 2;
      this.$scene.controls.mouseButtons = {
        ...this.$scene.controls.mouseButtons,
        LEFT: THREE.MOUSE.DOLLY,
        MIDDLE: THREE.MOUSE.PAN,
        RIGHT: THREE.MOUSE.ROTATE,
      };
      // this.$scene.controls.enableDamping = true;
      this.$scene.controls.addEventListener('wake', () => {
        // this.pauseSSAO = true;
      });
      this.$scene.controls.addEventListener('sleep', () => {
        // this.pauseSSAO = false;
        this.$scene.needsRender = true;
      });

      this.$scene.controls.domElement.focus = null;

      this.$scene.controls.addEventListener('change', () => {
        this.render();
      });
    },
    addLights() {
      for (const l in this.$scene.lights) {
        const lights = this.$scene.lights[l];

        if (!Array.isArray(lights)) {
          this.$scene.scene.add(lights);
        } else {
          for (const light of lights) {
            this.$scene.scene.add(light);
          }
        }
      }
    },
    addHelpers() {
      for (const h in this.$scene.helpers) {
        const helper = this.$scene.helpers[helper];

        if (!Array.isArray(h)) {
          this.$scene.scene.add(h);
        } else {
          for (const helper of h) {
            this.$scene.scene.add(helper);
          }
        }
      }
    },
    setComposer() {
      this.$scene.composer.reset();
      this.$scene.composer.setSize(
        this.$scene.container.clientWidth * 2,
        this.$scene.container.clientHeight * 2
      );
      this.$scene.composer.addPass(this.$scene.ssaoPass);
    },
    setSSAO() {
      this.$scene.ssaoPass.scene = this.$scene.scene;
      this.$scene.ssaoPass.camera = this.$scene.camera;
      this.$scene.ssaoPass.setSize(
        this.$scene.container.clientWidth,
        this.$scene.container.clientHeight
      );
    },
    rendererClick(e) {
      e.preventDefault();
      this.$scene.intersections = [];
      this.$scene.mousePosition = new Vector2();
      this.$scene.mousePosition.x = (e.x / e.target.clientWidth) * 2 - 1;
      this.$scene.mousePosition.y = -(e.y / e.target.clientHeight) * 2 + 1;
      this.$scene.raycaster.setFromCamera(
        this.$scene.mousePosition,
        this.$scene.camera
      );
      this.$scene.raycaster.intersectObject(
        this.$scene.modelsGroup,
        true,
        this.$scene.intersections
      );

      if (this.$scene.intersections.length > 0) {
        let clone = this.$scene.intersections[0].object.clone();
        console.log(this.$scene.intersections, clone);

        const selectedNodeData = JSON.parse(JSON.stringify(clone));

        selectedNodeData.userData = {
          ...this.$scene.intersections[0].object.userData,
        };

        clone = undefined;

        if (!this.$scene.selectedObject) {
          // No currently selected object. Store selection
          this.$scene.selectedObject = this.$scene.intersections[0].object;
          this.$scene.selectedObject.userData = {
            ...this.$scene.selectedObject.userData,
            baseMaterialColor:
              this.$scene.selectedObject.material.color.getHex(),
          };
          this.$emit('selected', selectedNodeData);
          this.$scene.selectedObject.material.setValues({
            emissive: 0xffffff,
            color: 0xff9900,
          });
        } else {
          // reset the original material

          this.$scene.selectedObject.material.setValues({
            color: this.$scene.selectedObject.userData.baseMaterialColor,
            emissive: 0x000000,
          });
          if (
            this.$scene.selectedObject.uuid ===
            this.$scene.intersections[0].object.uuid
          ) {
            // reselected the same object.
            this.$scene.selectedObject = null;
            this.$emit('selected', null);
          } else {
            // swap the selected object for another
            this.$scene.selectedObject = this.$scene.intersections[0].object;
            this.$scene.selectedObject.userData = {
              ...this.$scene.selectedObject.userData,
              baseMaterialColor:
                this.$scene.selectedObject.material.color.getHex(),
            };
            this.$emit('selected', selectedNodeData);
            this.$scene.selectedObject.material.setValues({
              emissive: 0xffffff,
              color: 0xff9900,
            });
          }
        }
      } else {
        // nothing clicked, reset and clear the existing selections
        if (this.$scene.selectedObject && this.$scene.selectedObject.material) {
          this.$scene.selectedObject.material.setValues({
            color: this.$scene.selectedObject.userData.baseMaterialColor,
            emissive: 0x000000,
          });
          this.$scene.selectedObject = null;
          this.$emit('selected', null);
        }
      }

      this.render();
    },

    setRenderer() {
      this.$scene.renderer.alpha = true;
      this.$scene.renderer.antialias = true;
      this.$scene.renderer.powerPreference = 'high-performance';
      this.$scene.renderer.toneMapping = THREE.ReinhardToneMapping;
      this.$scene.renderer.physicallyCorrectLights = true;
      this.$scene.renderer.outputEncoding = sRGBEncoding;
      this.$scene.renderer.shadowMap = {
        enabled: true,
        type: THREE.PCFSoftShadowMap,
      };
      this.$scene.renderer.setPixelRatio(window.devicePixelRatio);

      this.$scene.renderer.setClearColor(0x000000, 0); // the default

      this.updateRenderer();

      this.$refs.canvas.appendChild(this.$scene.renderer.domElement);
    },
    updateRenderer() {
      this.$scene.renderer.setSize(
        this.$scene.container.clientWidth,
        this.$scene.container.clientHeight
      );
    },
    render() {
      this.$scene.lights.camera.updateMatrix();
      this.$scene.lights.camera.updateMatrixWorld();
      if (this.$scene.postprocessing && !this.$scene.pauseSSAO) {
        // console.log(this.$scene.contextGroup);
        this.$scene.composer.render(this.$scene.scene, this.$scene.camera);
      } else {
        // console.log('renderer');
        this.$scene.renderer.render(this.$scene.scene, this.$scene.camera);
      }
    },
    animate() {
      const delta = this.$scene.clock.getDelta();

      for (const helper in this.$scene.helpers) {
        this.$scene.helpers[helper].update();
      }

      this.render();
    },
    loadModel(url) {
      return new Promise(resolve => {
        const loader = new GLTFLoader();
        const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath(
          'https://raw.githubusercontent.com/mrdoob/three.js/dev/examples/js/libs/draco/'
        );
        loader.setDRACOLoader(dracoLoader);
        loader.load(url, resolve);
      });
    },
    startAnimationLoop() {
      this.$scene.renderer.setAnimationLoop(() => {
        this.animate();
      });
    },
    stopAnimationLoop() {
      this.$scene.renderer.setAnimationLoop(null);
    },
    onResize() {
      this.$scene.camera.aspect =
        this.$scene.container.clientWidth / this.$scene.container.clientHeight;
      this.$scene.camera.updateProjectionMatrix();
      this.$scene.renderer.setSize(
        this.$scene.container.clientWidth,
        this.$scene.container.clientHeight
      );
      this.render();
    },
    zoomExtents(modelObject) {
      const boundingBox = new THREE.Box3().setFromObject(
        modelObject || this.$scene.modelsGroup
      );

      this.$scene.center = boundingBox.getCenter(new THREE.Vector3());

      this.$scene.boxHelper.setFromObject(this.$scene.modelsGroup);

      const size = boundingBox.getSize(new THREE.Vector3());

      this.$scene.lights.camera.target = this.$scene.modelsGroup;

      const maxDim = Math.max(size.x, size.y, size.z);

      const fov = this.$scene.camera.fov * (Math.PI / 180);
      let cameraZ = Math.abs((maxDim / 2) * Math.tan(fov * 2));
      const offset = 3;
      cameraZ *= offset; // zoom out a little so that objects don't fill the screen

      const minZ = boundingBox.min.z;
      const cameraToFarEdge = minZ < 0 ? -minZ + cameraZ : cameraZ - minZ;

      // if (modelObject) console.log('Zoom to Model');

      this.$scene.camera.far = cameraToFarEdge * 2;
      this.$scene.camera.position.y = size.y * 1.5;
      this.$scene.camera.updateProjectionMatrix();

      if (this.$scene.controls) {
        this.$scene.controls.target = this.$scene.center;
        this.$scene.controls.maxDistance = cameraToFarEdge;
        this.$scene.controls.update();
      } else {
        this.$scene.camera.lookAt(this.$scene.center);
      }
    },
  },
};
</script>

<template>
  <div id="canvas-wrapper" ref="canvas" v-resize.quiet="onResize">
    <v-progress-linear
      v-if="modelsLoading"
      :value="loadingProgress"
      :indeterminate="loadingProgress === 0"
      style="position: absolute; top: 0; width: 100%"
      color="action-base"
    />
  </div>
</template>

<style scoped>
#canvas-wrapper {
  width: calc(100%);
  height: calc(100vh);
}

canvas {
  width: calc(100%);

  height: 100%;
}
</style>
