var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"model-viewer-options"},[(_vm.selectedObject)?_c('v-card',{staticClass:"object-data",attrs:{"flat":"","rounded-xl":""}},[_c('v-card-text',[_c('pre',[_vm._v(_vm._s(JSON.stringify(
          {
            metadata: {
              ..._vm.selectedObject.metadata,
              uploadedBy: 'jonathon.broughton@grfn.com',
            },
            object: {
              name: _vm.selectedObject.object.name,
              type: _vm.selectedObject.object.type,
            },
            customData: _vm.selectedObject.userData,
            'GRFN Test Data': {
              'Has: TypeCode': false,
              'Valid: Type Code': false,
              'Is: Baseline': false,
              'Has: System': false,
              'Valid: System': false,
              'Has: Mark': false,
            },
          },
          undefined,
          '\t'
        )))])])],1):_vm._e(),_c('v-container',{staticClass:"model-scope-drawer",class:{ second: _vm.secondaryBar }},[_c('v-row',{staticClass:"main-bar rounded-lg",attrs:{"justify":"space-around"}},_vm._l((_vm.items),function(item){return _c('v-col',{key:item.title,staticClass:"pa-2"},[(item.type === 'spacer')?_c('v-spacer'):_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({class:{ 'active-button': _vm.toggleState(item.mode) },attrs:{"disabled":item.disabled,"icon":"","small":""},on:{"click":function($event){return _vm.handleMainBarClick(item)}}},'v-btn',attrs,false),on),[(!_vm.toggleState(item.mode))?[_c('v-icon',[_vm._v(_vm._s(item.icon))])]:(item.selectedIcon)?[_c('v-icon',[_vm._v(_vm._s(item.selectedIcon))])]:[_c('v-icon',[_vm._v(_vm._s(item.icon))])]],2)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.title)+" ")]),(item.options)?_c('span',[_vm._v(_vm._s(item.options[!_vm.toggleState(item.mode)]))]):_vm._e()])],1)}),1),(_vm.secondaryBar)?_c('v-row',{staticClass:"second-bar rounded-lg"},_vm._l((_vm.secondaryItems),function(item){return _c('v-col',{key:item.title,staticClass:"pa-2"},[_c('v-btn',{attrs:{"disabled":item.disabled,"small":""}},[_c('span',[_vm._v(_vm._s(item.title))])])],1)}),1):_vm._e()],1),(_vm.flyoutBar)?_c('v-container',{staticClass:"flyout"},[_c('v-treeview',{ref:"model-hierarchy",staticClass:"model-hierarchy",attrs:{"items":_vm.modelHierarchy,"multiple-active":_vm.multipleSelectioMode,"selectable":"","selected-color":"default--lighten-4","open-all":""}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }