<script>
import ModelCanvas from '../components/ModelCanvas.vue';
import ModelScopeDrawers from '../components/ModelScopeDrawers.vue';
import { mapState } from 'vuex';

export default {
  name: 'ModelViewer',
  components: { ModelCanvas, ModelScopeDrawers },
  data() {
    return { selectedObject: null, selectionModeOn: false };
  },
  computed: {
    ...mapState(['activeModelSet']),
    selectedProject() {
      return (
        this.$store.state.selectedProject &&
        this.$store.state.selectedProject[0]
      );
    },
    hasModels() {
      return this.selectedProject.key === this.$route.params.projectId;
    },
    modelSetLength() {
      return this.activeModelSet.length;
    },
  },
  watch: {},
  mounted() {
    this.changeActiveModelSet({
      contributor: 'HHBR',
      discipline: 'ar',
      projectKey: 'RSB',
    });
  },
  created() {
    this.changeActiveModelSet({
      contributor: 'GRFN',
      discipline: 'ma',
      projectKey: 'DMO',
    });
  },
  methods: {
    onSelected(object) {
      // console.log(object);
      this.selectedObject = object;
    },
    setSelectionMode(flag) {
      // console.log(flag);
      this.selectionModeOn = flag;
    },
    changeActiveModelSet(config) {
      const { contributor, discipline, projectKey } = config;

      this.$store.dispatch('setActiveModelSet', {
        contributor,
        discipline,
        projectKey,
      });
    },
  },
};
</script>

<template>
  <div>
    <!-- {{ selectedProject.key }} {{ $route.params.projectId }} {{ hasModels }} -->
    <model-scope-drawers
      :project-key="$route.params.projectId"
      :selected-object="selectedObject"
      :selection-mode-on="selectionModeOn"
      @toggle-selecting="setSelectionMode"
    />
    <keep-alive include="ModelCanvas">
      <model-canvas
        v-if="hasModels"
        :model-set="activeModelSet"
        :selection-mode-on="selectionModeOn"
        class="model-canvas"
        src="../assets/B_A-B-00-FFL.glb"
        @selected="onSelected"
      />
    </keep-alive>
  </div>
</template>

<style lang="scss" scoped>
.model-canvas {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}
</style>
